/**
 * Cell renderer actions functionality to single route modules
 * @mixin views/modules/mixins/commonSingleCellRendererActions
 *
 * @vue-computed {Object | null} dataRowRecord - data of row record showed
 * @vue-computed {string | null} idRowRecord - id of row record showed
 * @vue-computed {string | null} nameRowRecord - name of row record showed
 * @vue-computed {Object[]} actionsFromParent - actions from parent
 * @vue-computed {Object[]} filteredActionsFromParent - active actions from parent
 * @vue-computed {Object} confirmDeleteRecordDialogParams - confirm delete dialog params
 * @vue-computed {Object} deleteSuccessNotificationParams - delete success notification params
 * @vue-event {void} viewRecord - call component parent to view a selected record
 * @vue-event {void} editRecord - call component parent to edit a selected record
 * @vue-event {void} cloneRecord - call component parent to clone a selected record
 * @vue-event {void} confirmDeleteRecord - call component parent to confirm a delete record
 */
export default {
  computed: {
    dataRowRecord() {
      return this.params && this.params.node && this.params.node.data
        ? this.params.node.data
        : null;
    },
    idRowRecord() {
      return this.dataRowRecord
        ? this.dataRowRecord.id
        : null;
    },
    nameRowRecord() {
      return this.dataRowRecord
        ? this.dataRowRecord.name
        : null;
    },
    actionsFromParent() {
      return this.params && this.params.context && this.params.context.componentParent
        ? this.params.context.componentParent.actionsOnList
        : [];
    },
    filteredActionsFromParent() {
      return this.actionsFromParent.filter((action) => action.active);
    },
    confirmDeleteRecordDialogParams() {
      return {};
    },
    deleteSuccessNotificationParams() {
      return {};
    },
  },
  methods: {
    viewRecord() {
      this.params.context.componentParent.viewRecord(this.dataRowRecord);
    },
    editRecord() {
      this.params.context.componentParent.editRecord(this.dataRowRecord);
    },
    cloneRecord() {
      this.params.context.componentParent.cloneRecord(this.dataRowRecord);
    },
    async confirmDeleteRecord() {
      return this.params.context.componentParent.confirmDeleteRecord(
        this.nameRowRecord, this.idRowRecord,
        this.confirmDeleteRecordDialogParams,
        this.deleteSuccessNotificationParams,
      );
    },
    resendRecord() {
      this.params.context.componentParent.resendRecord(this.dataRowRecord);
    },
  },
};
