/**
 * This provides methods used for list, create and edit
 * @mixin views/modules/mixins/commonListCreateOrEdit
 *
 * @vue-data {string} [entity=''] - Entity to list and make operations of create and edit
 * @vue-data {boolean} [activeModalCreateOrEdit=false] -
 * to active or inactive the create or edit modal
 * @vue-data {boolean} [activeModalView=false] - to active or inactive the view modal
 * @vue-data {boolean} [showCreateOrEditComponent=false] -
 * to show or hide the create or edit component
 * @vue-data {boolean} [activeModalToExport=false] - to active or inactive the export modal
 * @vue-data {boolean} [showExportComponent=false] - to show or hide the export component
 * @vue-data {boolean} [showImportComponent=false] - to show or hide the import component
 * @vue-data {number} [importComponentKey=0] - import component key
 * @vue-data {string} [operation='create'] - indicate the operation to make ('create', 'edit')
 * @vue-data {Object} [recordSelected=null] - row or record selected to edit
 * @vue-data {number} [modalsDelay=500] - delay of component inside modal to hide
 * @vue-data {boolean} [exportingList=false] - indicate if exporting operations is in progress
 * @vue-data {boolean} [exportedList=false] - indicate if exporting operations is in done
 * @vue-data {string} [urlFileExported=''] - url to download the exported file
 * @vue-data {boolean} createOrEditModelWasChanged - indicate if model on create or edit was changed
 * @vue-data {boolean} viewDisabled - indicate if view operation is disabled
 * @vue-data {boolean} cloneDisabled - indicate if clone operation if disabled
 * @vue-data {boolean} editDisabled - indicate if edit operation if disabled
 * @vue-data {boolean} deleteDisabled - indicate if delete operation if disabled
 * @vue-data {boolean} dontConfirmCloseCreateOrEdit -
 * indicate if no show confirm to close create or edit
 * @vue-data {Function | null} deleteRecordFunction - function to remove one record
 * @vue-data {Function | null} afterDeleteRecordFunction - function to call after delete one record
 * @vue-data {string} redirectRouteNameFromCreateOrEdit -
 * route name for redirection from create or edit
 * @vue-computed {Object[]} [defaultMultipleActionOptions=[...]] -
 * default multiple action options to actions menu
 * @vue-computed {string} titleModalName - payload name to show in title modal
 * @vue-computed {string} titleModal - title of create or edit modal
 * @vue-computed {string} headerButtonsSizeByResolution - size for header buttons
 * @vue-computed {string} exportModalTitle - title of export modal
 * @vue-computed {Object[]} [additionalActionsOnList=[]] - additional actions on list
 * @vue-computed {Object[]} actionsOnList=[] - actions on list
 * @vue-computed {Object[]} actionsOnCreateOrEdit - options to actions in create or edit
 * @vue-event {void} confirmCloseCreateOrEditModal - called to confirm close of create or edit
 * @vue-event {void} onActionFromCreateOrEdit - called to make action form create or edit
 * @vue-event {void} viewRecord - show create or edit modal to view
 * @vue-event {void} newItem - show create or edit modal to create
 * @vue-event {void} savedItem - called after item is saved to hide create or edit modal
 * @vue-event {void} cloneRecord - show create or edit modal to clone
 * @vue-event {void} editRecord - show create or edit modal to edit
 * @vue-event {Promise<boolean>} confirmDeleteRecord - show confirm dialog to delete a record
 * @vue-event {void} deleteRecord - delete a record
 * @vue-event {void} showDeleteSuccess - show deleted record notification
 * @vue-event {void} showNoSelectedRowsMessage - show dialog to indicate that no rows are selected
 * @vue-event {void} exportList - make export operation of a list
 * @vue-event {void} deleteFromCreateOrEdit - call on delete form create or edit
 */
export default {
  data() {
    return {
      entity: '',
      activeModalCreateOrEdit: false,
      activeModalView: false,
      showCreateOrEditComponent: false,
      activeModalToExport: false,
      showExportComponent: false,
      showImportComponent: false,
      importComponentKey: 0,
      operation: this.$enums.Operation.CREATE,
      recordSelected: null,
      modalsDelay: 500,
      exportingList: false,
      exportedList: false,
      exportSendEmail: false,
      urlFileExported: '',
      createOrEditModelWasChanged: false,
      viewDisabled: false,
      cloneDisabled: false,
      editDisabled: false,
      deleteDisabled: false,
      exportDisabled: false,
      dontConfirmCloseCreateOrEdit: false,
      deleteRecordFunction: null,
      afterDeleteRecordFunction: () => {},
      redirectRouteNameFromCreateOrEdit: '',
    };
  },
  computed: {
    defaultMultipleActionOptions() {
      const actions = [{
        label: this.$t('$General.Delete'),
        event: this.$enums.Operation.DELETE,
        icon: 'TrashIcon',
      }];

      if (!this.exportDisabled) {
        actions.push({
          label: this.$t('$General.Export'),
          event: this.$enums.Operation.EXPORT,
          icon: 'DownloadIcon',
        });
      }

      return actions;
    },
    titleModalName() {
      return this.recordSelected && this.recordSelected.name
        ? this.recordSelected.name : '';
    },
    titleModal() {
      switch (this.operation) {
        case this.$enums.Operation.CREATE:
          return this.$t('$Modals.CreateModalTitle', { entity: this.$tc(`$Entities.${this.entity}`).toLowerCase() });

        case this.$enums.Operation.CLONE:
          return this.$t('$Modals.CloneModalTitle', {
            entity: this.$tc(`$Entities.${this.entity}`),
            name: this.titleModalName,
          });

        case this.$enums.Operation.EDIT:
          return this.$t('$Modals.EditModalTitle', {
            entity: this.$tc(`$Entities.${this.entity}`),
            name: this.titleModalName,
          });

        case this.$enums.Operation.VIEW:
          return this.$t('$Modals.ViewModalTitle', {
            entity: this.$tc(`$Entities.${this.entity}`),
            name: this.titleModalName,
          });

        default:
          return '';
      }
    },
    headerButtonsSizeByResolution() {
      return this.$mq !== this.$enums.mqBreakpoints.MOBILE ? 'default' : 'small';
    },
    exportModalTitle() {
      return this.$t('$Modals.ExportModalTitle', {
        entity: this.$tc(`$Entities.${this.entity}`, this.rowsSelectedCount),
      });
    },
    additionalActionsOnList() {
      return [];
    },
    actionsOnList() {
      return [
        ...this.additionalActionsOnList,
        {
          name: this.$enums.Operation.VIEW,
          color: 'primary',
          text: '$General.View',
          position: 'top',
          icon: 'icon-eye',
          iconVue: 'EyeIcon',
          activeCreateOrEdit: false,
          active: !this.viewDisabled,
        },
        {
          name: this.$enums.Operation.EDIT,
          color: 'warning',
          text: '$General.Edit',
          position: 'top',
          icon: 'icon-edit',
          iconVue: 'EditIcon',
          activeList: true,
          activeCreateOrEdit: this.operation === this.$enums.Operation.VIEW,
          active: !this.editDisabled,
        },
        {
          name: this.$enums.Operation.CLONE,
          color: 'primary',
          text: '$General.Clone',
          position: 'top',
          icon: 'icon-copy',
          iconVue: 'CopyIcon',
          activeCreateOrEdit: this.operation === this.$enums.Operation.VIEW,
          active: !this.cloneDisabled,
        },
        {
          name: this.$enums.Operation.DELETE,
          color: 'danger',
          text: '$General.Delete',
          position: 'top',
          icon: 'icon-trash-2',
          iconVue: 'Trash2Icon',
          activeCreateOrEdit: this.operation === this.$enums.Operation.VIEW
            || this.operation === this.$enums.Operation.EDIT,
          active: !this.deleteDisabled,
        },
      ];
    },
    actionsOnCreateOrEdit() {
      return this.actionsOnList.map((action) => ({
        ...action,
        active: action.activeCreateOrEdit && action.active,
      }));
    },
  },
  watch: {
    async activeModalCreateOrEdit(val) {
      if (val) {
        this.showCreateOrEditComponent = true;
      } else if (this.dontConfirmCloseCreateOrEdit) {
        setTimeout(() => {
          this.showCreateOrEditComponent = false;
        }, this.modalsDelay);
        this.dontConfirmCloseCreateOrEdit = false;
      } else {
        const confirmed = await this.confirmCloseCreateOrEditModal();

        if (confirmed) {
          this.recordSelected = null;
          this.createOrEditModelWasChanged = false;

          setTimeout(() => {
            this.showCreateOrEditComponent = false;
            if (this.redirectRouteNameFromCreateOrEdit) {
              this.$router.push({ name: this.redirectRouteNameFromCreateOrEdit });
              this.redirectRouteNameFromCreateOrEdit = '';
            }
          }, this.modalsDelay);
        } else {
          this.activeModalCreateOrEdit = true;
        }
      }
    },
    activeModalToExport(val) {
      if (!val) {
        setTimeout(() => {
          this.showExportComponent = false;
        }, this.modalsDelay);
      } else {
        this.showExportComponent = true;
      }
    },
  },
  methods: {
    redirectFromCreateOrEdit(routeName) {
      this.redirectRouteNameFromCreateOrEdit = routeName;
      this.activeModalCreateOrEdit = false;
    },
    async confirmCloseCreateOrEditModal() {
      if ((this.operation === this.$enums.Operation.CREATE
        || this.operation === this.$enums.Operation.EDIT
        || this.operation === this.$enums.Operation.CLONE)
        && this.createOrEditModelWasChanged) {
        return new Promise((resolve) => {
          this.$showConfirmWarningDialog({
            title: this.$t('$Dialogs.ConfirmCloseWithoutSaveChangesTitle'),
            text: this.$t('$Dialogs.ConfirmCloseWithoutSaveChangesMsg'),
            accept: () => resolve(true),
            cancel: () => resolve(false),
            acceptText: this.$t('$General.Confirm'),
            cancelText: this.$t('$General.Back'),
          });
        });
      }

      return true;
    },
    onActionFromCreateOrEdit(action) {
      switch (action) {
        case this.$enums.Operation.EDIT:
          this.editRecord(this.recordSelected);
          break;

        case this.$enums.Operation.CLONE:
          this.cloneRecord(this.recordSelected);
          break;

        case this.$enums.Operation.DELETE:
          this.deleteFromCreateOrEdit(this.recordSelected);
          break;

        default:
      }
    },
    viewRecord(recordSelected = null) {
      this.operation = this.$enums.Operation.VIEW;
      this.recordSelected = recordSelected;
      this.activeModalCreateOrEdit = true;
    },
    newItem() {
      this.operation = this.$enums.Operation.CREATE;
      this.activeModalCreateOrEdit = true;
    },
    savedItem() {
      this.dontConfirmCloseCreateOrEdit = true;
      this.activeModalCreateOrEdit = false;
      this.createOrEditModelWasChanged = false;
    },
    cloneRecord(recordSelected = null) {
      this.operation = this.$enums.Operation.CLONE;
      this.recordSelected = recordSelected;
      this.activeModalCreateOrEdit = true;
    },
    editRecord(recordSelected = null) {
      this.operation = this.$enums.Operation.EDIT;
      this.recordSelected = recordSelected;
      this.activeModalCreateOrEdit = true;
    },
    async confirmDeleteRecord(
      name = '', id = null,
      confirmDeleteRecordDialogParams = {},
      deleteSuccessNotificationParams = {},
    ) {
      return new Promise((resolve) => {
        this.$vs.dialog({
          type: confirmDeleteRecordDialogParams.type || 'confirm',
          color: confirmDeleteRecordDialogParams.color || 'danger',
          title: confirmDeleteRecordDialogParams.title || this.$t('$Dialogs.ConfirmDeleteTitle'),
          text: confirmDeleteRecordDialogParams.text || ((name !== '') ? this.$t('$Dialogs.ConfirmSpecificDeleteMsg', { entity: name }) : this.$t('$Dialogs.ConfirmSpecificOneDeleteMsg', { entity: this.$tc(`$Entities.${this.entity}`) })),
          accept: () => {
            this.deleteRecord(id, deleteSuccessNotificationParams);
            resolve(true);
          },
          cancel: () => resolve(false),
          acceptText: confirmDeleteRecordDialogParams.acceptText || this.$t('$General.Delete'),
          cancelText: confirmDeleteRecordDialogParams.cancelText || this.$t('$General.Cancel'),
          buttonAccept: 'accept-fill',
          buttonCancel: 'cancel-flat',
        });
      });
    },
    async deleteRecord(id, deleteSuccessNotificationParams = {}) {
      if (typeof this.deleteRecordFunction !== 'function') {
        throw new Error('value must ve a valid function');
      }

      this.$vs.loading({ type: 'radius' });
      await this.deleteRecordFunction(id);

      if (typeof this.afterDeleteRecordFunction === 'function') {
        this.afterDeleteRecordFunction();
      }

      this.$vs.loading.close();
      this.showDeleteSuccess(deleteSuccessNotificationParams);
    },
    showDeleteSuccess(deleteSuccessNotificationParams = {}) {
      this.$showSuccessActionNotification({
        title: deleteSuccessNotificationParams.title
          || this.$t(deleteSuccessNotificationParams.$title || '$Notifications.DeletedTitle',
            { entity: this.$tc(`$Entities.${this.entity}`) }),
        text: deleteSuccessNotificationParams.msg
          || this.$tc(deleteSuccessNotificationParams.$msg || '$Notifications.DeletedMsg',
            1,
            { entity: this.$tc(`$Entities.${this.entity}`) }),
      });
    },
    showNoSelectedRowsMessage() {
      this.$showGeneralWarningNotification({
        title: this.$t('$Notifications.NoSelectionTitle', {
          entity: this.$tc(`$Entities.${this.entity}`, 2),
        }),
        text: this.$t('$Notifications.NoSelectionMsg', {
          entity: this.$tc(`$Entities.${this.entity}`),
        }),
      });
    },
    async exportList({
      columns = [], name = '', format = '', data = null,
      filters = {}, sortBy = [], separator = '', sendEmail = false,
      exportFunction = async () => '', entity = '',
    }) {
      this.activeModalToExport = false;
      this.exportSendEmail = sendEmail;
      this.exportingList = true;

      this.urlFileExported = await exportFunction({
        columns,
        name,
        format,
        data: data !== null ? data.map((d) => d.id) : null,
        filters,
        sortBy,
        separator,
        sendEmail,
        entity,
      });

      this.exportingList = false;
      this.exportedList = true;
    },
    async deleteFromCreateOrEdit(payload) {
      this.dontConfirmCloseCreateOrEdit = true;
      this.activeModalCreateOrEdit = false;

      const confirmed = await this.confirmDeleteRecord(payload.name, payload.id);

      if (confirmed) {
        this.showCreateOrEditComponent = false;
      } else {
        this.activeModalCreateOrEdit = true;
      }

      this.dontConfirmCloseCreateOrEdit = false;
    },
  },
};
